.main__wall___3qH2o {
  -webkit-column-width: 300px;
          column-width: 300px;
  -webkit-column-gap: 10px;
          column-gap: 10px;
  padding: 10px;
}

.main__brick___3kc6l {
  margin-bottom: 10px;
  width: 100%;
  display: block;
  transition: -webkit-transform 0.15s ease-out;
  transition: transform 0.15s ease-out;
  transition: transform 0.15s ease-out, -webkit-transform 0.15s ease-out;
  cursor: pointer;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden
}

.main__brick___3kc6l:hover {
  -webkit-transform: scale(1.02);
          transform: scale(1.02);
}
